import './styles/landing/main.less'

type OS = 'android' | 'ios'

const $ = (selector: string) => document.getElementById(selector)!

const init = () => {
	const platform = {
		isIos: /iPad|iPhone|iPod/i.test(navigator.userAgent),
		isAndroid: navigator.userAgent.indexOf('Android') > -1,
	}

	const elm = {
		root: document.documentElement,
		btnIos: $('btn-ios'),
		btnAndroid: $('btn-android'),
		videoIos: $('video-ios') as HTMLVideoElement,
		videoAndroid: $('video-android') as HTMLVideoElement,
	}

	const switchOs = (os: OS) => {
		elm.root!.classList.remove('ios', 'android')
		elm.root!.classList.add(os)
		elm.btnIos.classList.toggle('active', os === 'ios')
		elm.btnAndroid.classList.toggle('active', os === 'android')

		// iOS will not autplay, but play in fullscreen if calling play()
		if (!platform.isIos) {
			if (os === 'ios') {
				elm.videoAndroid.pause()
				elm.videoIos.play()
			} else {
				elm.videoAndroid.play()
				elm.videoIos.pause()
			}
		}
	}

	elm.btnIos.onclick = () => switchOs('ios')
	elm.btnAndroid.onclick = () => switchOs('android')

	switchOs(platform.isAndroid ? 'android' : 'ios')
}

document.addEventListener('DOMContentLoaded', init)
